* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

body {
  background-color: #007dbc;
}

.red {
  color: #ff3838;
}

.popup-alert {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  border-radius: 0 !important;
}

.welcomeText {
  display: flex;
  font-size: 28px;
  justify-content: center;
  margin-bottom: 14px;
  text-align: center;
}

.loginContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-container {
  display: flex;
  width: 480px;
  height: 90px;
  justify-content: center;
  margin-top: 20px;
}

.dkt-logo {
  width: 100%;
  border-radius: 8px;
}

.loginButton {
  padding: 8px 20px !important;
}

.buttonArea {
  background-color: #f1f1f1;
  border: none;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 #5a5a57;
  display: block;
  flex-flow: column;
  padding: 40px 30px;
  text-align: center;
  width: 60%;
}

.containerTitle {
  color: #000;
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.navbar {
  display: flex;
  background-color: rgb(56, 56, 56);
  padding: 16px 0;
}

.form-title {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: #fff;
}

.description {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  color: #fff;
}

.description > p {
  max-width: 1100px;
  text-align: center;
  width: 60%;
}

.site-logo {
  color: #fff;
  font-size: 20px;
  margin-left: 10px;
}

.form-container {
  max-width: 1400px;
  margin: 16px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.label {
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  margin: 20px 0 12px 0;
}

.gender-div {
  display: flex;
}

.gender-div > p {
  margin-right: 10px;
}

.radio-container {
  margin: 0 !important;
  padding-top: 8px !important;
}

.radio-container > label:first-child {
  margin-right: 4px !important;
}

.radio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 24px;
  gap: 10px;
}

.cost {
  display: block;
  text-align: center;
}

.cost > input {
  margin-top: 6px;
  width: 100%;
}

.submit {
  display: flex;
  margin: 0 auto;
  font-weight: 600;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  background-color: #ff600a;
  border: none;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  padding: 18px 120px;
  cursor: pointer;
}

input[type="radio"] {
  border: 0px;
  width: 10%;
  height: 1.6em;
}

select {
  display: flex;
  margin: 0 auto;
  width: 100%;
  padding: 16px;
}

.right-area {
  margin-top: 10px;
}

.display-none {
  display: none;
}

#cost {
  padding: 16px;
}

#surveyForm {
  width: 40%;
  background-color: #fff;
  padding: 6px 18px 16px 18px;
  border-radius: 4px;
}

.completed-surveys {
  color: #fff;
  display: flex;
  width: 70%;
  justify-content: center;
  background-color: #ff600a;
  padding: 6px;
  border-radius: 4px;
  margin: 12px auto -3px auto;
  font-weight: bold;
}

.wiki-container {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  width: 40%;
  margin-top: 1rem;
}

.wiki-link {
  width: 100%;
}

.wiki-tur,
.wiki-eng {
  background-color: white;
  padding: 20px 26px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.wiki-icon {
  color: #007dbc !important;
  width: 1.2em !important;
  height: 1.2em !important;
  margin-right: 8px !important;
}

a {
  color: #007dbc;
  text-decoration: none;
}

.notFound {
  color: #fff;
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 230px 0 20px 0;
  padding: 0 8px;
}

.returnToStoreSelection {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-size: 24px;
  background-color: #f8f8f8;
  font-weight: 700;
  color: rgb(73, 73, 73);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 40px 90px;
}

.prevent-clicking {
  opacity: 0.5;
  cursor: not-allowed;
}

@media only screen and (max-width: 660px) and (min-width: 200px) {
  .logo-container {
    width: 80%;
  }
  .returnToStoreSelection {
    padding: 30px 60px;
  }
}

@media only screen and (max-width: 990px) and (min-width: 200px) {
  #surveyForm {
    width: 90%;
  }

  .buttonArea {
    width: 90%;
  }

  .description > span {
    padding: 0 20px;
  }

  .popup-alert {
    bottom: 0 !important;
    margin: 0 !important;
    position: fixed !important;
    width: 100%;
  }

  .form-title {
    font-size: 14px;
  }

  .description {
    font-size: 14px;
  }

  button {
    white-space: nowrap;
  }

  select,
  .cost > input {
    width: 80%;
  }

  .description > p {
    margin: 5px 20px;
    width: 100%;
  }

  .form-container {
    margin-top: 12px;
    margin-bottom: 32px;
  }
}
